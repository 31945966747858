@page {
    size: 3in auto;
    margin: 0;
}

.bill-print {
    width: 3in;
    padding: 0 10px 10px 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*border: solid; /* Adjusted border property */
    /*border-color: #000000; /* Set border color */
    position: relative;
    margin: 0;
}

.header {
    text-align: center;
    margin-bottom: 4px;
}

.logo {
    margin: 4px;
    width: 100px;
    height: auto;
}

.address,
.contact {
    font-size: 12px;
    font-weight: bold;
}

.invoice-info {
    text-align: left;
    width: 100%;
}

.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.item-table {
    width: 100%;
    border-collapse: collapse;
}

.item-table th,
.item-table td {
    font-size: 10px;
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 1px;
    padding-left: 4px;
}

.item-table th {
    background-color: #f0f0f0;
}

.item-table th+td {
    border-top: 2px solid black;
}

.summary {
    width: 100%;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 4px;
}

.label {
    padding-left: 120px;
}

.footer {
    text-align: center;
    margin-bottom: 4px;
    font-size: 8px;
}

hr {
    border: 1px solid black;
    width: 100%;
}

@media print {
    .bill-print {
        margin: 0;
        padding: 0 10px 10px 10px;
        font-size: 10px;
        page-break-inside: avoid;
    }
}

.copy-label {
    position: absolute;
    top: 10px;
    right: 8px;
    font-weight: bold;
    font-size: 12px;
    color: black;
    text-align: right;
    width: auto;
    border: #000000, solid, 2px;
    padding: 3px;
}

/*@media print {
    body {
        margin: 0;
    }

    .bill-print {
        margin: 0;
        padding: 10px;
        font-size: 10px;
        page-break-inside: avoid;
    }
}*/