.pagination {
  display: flex;
  justify-content: center;
  padding: 1em;
}

.pagination li {
  margin: 0 5px; /* Space between page numbers */
  list-style: none; /* Remove default bullet points */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.pagination .active {
  font-weight: bold;
  text-decoration: underline;
  cursor: default; /* Optional: keep default cursor on active page */
}

.pagination .break-me {
  margin: 0 10px; /* Space around dots */
  display: flex;
  align-items: center; /* Align dots vertically with text */
}

.pagination .break-me::before {
  content: '...'; /* Add ellipsis before the break */
  margin-right: 5px; /* Space before the dot */
  font-weight: bold; /* Optional: make dots bold */
}
